<!-- 内部媒体 -->
<template>
  <div class="internalMedia">
    <div class="MediaList">
      <h4 class="Tit">甘肃建设报</h4>
      <div class="MLeft">
        <img src="../../../../assets/image/internalMedia1.jpg" />
      </div>
      <div class="MRightList" >
        <div class="MRight" v-for="(item, index) in paperList01" :key="index">
          <p>
            <span class="newsPaperInner" @click="handlePaper(item)">
              {{ item.title }}
            </span>
          </p>
        </div>
      </div>
    </div>

    <div class="MediaList">
      <h4 class="Tit">电视新闻</h4>
      <div class="MLeft">
        <img src="../../../../assets/image/internalMedia2.png" />
      </div>
      <div class="TVList">
        <div class="MRight" v-for="(item, index) in paperList02" :key="index">
          <p>
            <a :href="item.url" target="_blank" class="newsPaperInner">
              {{ item.title }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArtList, getArtInfo } from '@/api/common'
export default {
  components: {},
  data() {
    return {
      paperList01: [],
      paperList02: []
    }
  },
  computed: {},
  mounted() {
    var _this = this

    _this.getArtList()
  },
  methods: {
    getArtList() {
      var _this = this
      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '001900100004'
      }
      getArtList(data01).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.paperList01 = data
        }
      })

      let data02 = {
        channelUuid: '001900100001'
      }
      getArtList(data02).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.paperList02 = data
        }
      })
    },
    handlePaper(item) {
      var _this = this

      let data = {
        entTApplicationUuid: _this.webId,
        entTArticleUuid: item.entTArticleUuid
      }
      getArtInfo(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsitem = data.content
          let content = data.content.split('href="')[1].split('"')[0]
          window.open(content)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.internalMedia {
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  background-color: #ffffff;
  line-height: 24px;
}
.MediaList {
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #c2c2c2;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.MediaList .Tit {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
  text-align: left;
}
.MediaList .MLeft {
  position: relative;
  float: left;
  background: #eeecef;
  margin: 6px 20px 0 6px;
  padding: 5px;
}
.MediaList .MLeft img {
  width: 182px;
  height: auto;
}
.MRightList{
  width: 500px;
  height: 200px;
  overflow: auto;
}
.TVList{
  width: 980px;
  float: right;
}
.MediaList .MRight {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.MediaList .MRight p {
  line-height: 35px;
  margin: 0;
}
.MediaList .MRight a {
  color: #000;
}
.newsPaperInner {
  display: inline-block;
  font-size: 16px;
  line-height: 32px;
  cursor: pointer;
}
.newsPaperInner:hover {
  color: #0162b3;
}
</style>
